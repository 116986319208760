"use client";
import { Button as ThemesButton } from "@radix-ui/themes";
import React from "react";
import classNames from "classnames";
import styles from "./ButtonV2.module.scss";

interface ThemesButtonProps
  extends React.ComponentPropsWithoutRef<typeof ThemesButton> {}

interface ButtonOwnProps {
  color?: "mint" | "gray";
  variant?: "solid" | "outline" | "ghost" | "soft" | "surface";
  size?: ThemesButtonProps["size"];
  state?: "normal" | "disabled" | "loading";
  highContrast?: boolean;
}

interface ButtonProps
  extends Omit<ThemesButtonProps, "color" | "variant">,
    ButtonOwnProps {}

const ButtonV2 = React.forwardRef<HTMLButtonElement, ButtonProps>( // correct syntax
  (
    {
      color = "mint",
      variant = "solid",
      radius = "full",
      state = "normal",
      highContrast = !["solid"].includes(variant),
      className,
      onClick,
      ...props
    }: ButtonProps,
    forwardedRef: React.Ref<HTMLButtonElement>,
  ) => {
    let variantColorClass;
    if (variant === "ghost") {
      if (color === "mint") {
        variantColorClass = styles.ghostMint;
      } else {
        // eslint-disable-next-line no-console
        console.error("Only mint ghost button currently supported");
        variantColorClass = styles.ghostMint;
      }
    }

    return (
      <ThemesButton
        ref={forwardedRef}
        aria-disabled={state === "normal" ? undefined : true}
        aria-label={state === "loading" ? "Loading, please wait." : undefined}
        aria-live={"assertive"}
        className={classNames(
          className,
          styles.buttonBase,
          styles[variant],
          variantColorClass,
          {
            "state-loading": state === "loading",
          },
        )}
        color={color}
        data-disabled={state === "normal" ? undefined : true}
        highContrast={highContrast}
        onClick={(event) => {
          const ariaDisabled =
            event.currentTarget.getAttribute("aria-disabled");
          if (ariaDisabled !== null && ariaDisabled !== "false") {
            event.preventDefault();
            event.stopPropagation();
            return;
          }
          onClick?.(event);
        }}
        radius={radius}
        type={"button"}
        loading={state === "loading"}
        variant={(() => variant)()}
        {...props}
      />
    );
  },
);

ButtonV2.displayName = "ButtonV2";

export { ButtonV2 };

export type { ButtonProps };
