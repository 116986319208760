// https://github.com/radix-ui/primitives/issues/986#issuecomment-1055825332
// https://github.com/radix-ui/design-system/blob/master/components/Sheet.tsx
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { Cross1Icon } from "@radix-ui/react-icons";
import * as RadixTheme from "@radix-ui/themes";
import React from "react";
import styled, { css } from "styled-components";
import { QUERIES } from "../breakpoints";
import { Button } from "./Button";

type SheetDirectionProps = { direction?: "ltr" | "rtl" };
const Sheet = DialogPrimitive.Root;
const SheetTrigger = DialogPrimitive.Trigger;

const StyledOverlay = styled(DialogPrimitive.Overlay)`
  position: "fixed";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  [data-state="open"] {
    animation: fadeIn 150ms cubic-bezier(0.22, 1, 0.36, 1);
  }

  [data-state="closed"] {
    animation: fadeOut 150ms cubic-bezier(0.22, 1, 0.36, 1);
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

const StyledContent = styled(DialogPrimitive.Content)<SheetDirectionProps>`
  background-color: var(--grey-02);
  position: fixed;
  top: 0;
  bottom: 0;
  min-width: 500px;
  max-width: 100vw;

  @media ${QUERIES.mobile} {
    min-width: unset;
    width: 100vw;
  }

  ${(props) =>
    props.direction === "rtl"
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `}

  &[data-state="open"] {
    ${(props) =>
      props.direction === "rtl"
        ? css`
            animation: slideInRtl 300ms cubic-bezier(0.22, 1, 0.36, 1);
          `
        : css`
            animation: slideInLtr 300ms cubic-bezier(0.22, 1, 0.36, 1);
          `}
  }

  &[data-state="closed"] {
    ${(props) =>
      props.direction === "rtl"
        ? css`
            animation: slideOutRtl 300ms cubic-bezier(0.22, 1, 0.36, 1);
          `
        : css`
            animation: slideOutLtr 300ms cubic-bezier(0.22, 1, 0.36, 1);
          `}
  }

  @keyframes slideInLtr {
    from {
      transform: translate3d(-100%, 0, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes slideOutLtr {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes slideInRtl {
    from {
      transform: translate3d(100%, 0, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes slideOutRtl {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(100%, 0, 0);
    }
  }
`;

const StyledCloseButton = styled(DialogPrimitive.Close)`
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  padding: 12px;
  margin: 12px;

  &:hover,
  &:focus {
    background-color: var(--grey-light);
    border-radius: 50%;
  }
`;

function Portal({ children }: React.PropsWithChildren) {
  const [container, setContainer] = React.useState<HTMLElement>();

  React.useEffect(() => {
    // effect are only executed in the browser
    setContainer(document.getElementById("radix-themed-portal") ?? undefined);
  }, []);

  return (
    <DialogPrimitive.Portal container={container}>
      {children}
    </DialogPrimitive.Portal>
  );
}

type SheetContentProps = React.ComponentProps<typeof DialogPrimitive.Content> &
  SheetDirectionProps;
const SheetContent = React.forwardRef<HTMLDivElement, SheetContentProps>(
  function SheetContentProps({ children, ...props }, forwardedRef) {
    return (
      <Portal>
        <StyledOverlay />
        <StyledContent {...props} ref={forwardedRef}>
          <RadixTheme.Box position={"relative"}>
            {children}
            <StyledCloseButton asChild>
              <Button tertiary tiny>
                <Cross1Icon />
              </Button>
            </StyledCloseButton>
          </RadixTheme.Box>
        </StyledContent>
      </Portal>
    );
  },
);

const SheetClose = DialogPrimitive.Close;
const SheetTitle = DialogPrimitive.Title;
const SheetDescription = DialogPrimitive.Description;

export {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetTitle,
  SheetTrigger
};
export type { SheetDirectionProps };

