"use client"; // due to styled components
import * as RadixTheme from "@radix-ui/themes";
import React from "react";
import styled, { css } from "styled-components";
import Spinner from "./Spinner";

const LoadingIcon = styled(Spinner)``;

const BaseButtonCss = css<{ loading?: boolean }>`
  color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;

  position: relative;
  min-width: 120px;
  padding: 12px 24px;
  border-radius: 24px;
  border: none;
  outline: none;
  user-select: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  ${(props) =>
    props.loading &&
    css`
      padding: 0 60px;
    `}
  ${LoadingIcon} {
    position: absolute;
    left: 0;
    top: 0;
    width: 32px;
    height: 32px;
    margin: 8px;
  }
`;

const PrimaryButtonCss = css<{ loading?: boolean }>`
  ${BaseButtonCss}
  color: var(--primary-color);
  background-color: var(--green);

  &:hover {
    background-color: var(--green-over);
  }

  &:disabled {
    background-color: var(--grey-light);
    color: var(--grey-dark-1);
  }

  ${(props) =>
    props.loading &&
    css`
      color: var(--primary-color);
      background-color: var(--green-loading);
    `}

  ${LoadingIcon} {
    stroke: var(--white);
  }
`;

const SecondaryButtonCss = css`
  ${BaseButtonCss}
  background-color: var(--green-low);

  &:hover {
    background-color: var(--green-low-over);
  }

  ${LoadingIcon} {
    stroke: var(--green);
  }
`;

const TertiaryButtonCss = css`
  ${BaseButtonCss}

  background: none;
  &:hover {
    background-color: var(--grey-medium-over);
  }

  ${LoadingIcon} {
    stroke: var(--green);
  }
`;

const LargeButtonCss = css`
  width: 100%;
`;

const TinyButtonCss = css`
  padding: 0;
  min-width: 0;
  min-height: 0;
`;

const shouldForwardProp = (propName: string) =>
  !["primary", "secondary", "tertiary", "loading", "tiny", "large"].includes(
    propName,
  );

// tertiary is not supported for now
type ButtonProps = {
  primary?: true;
  secondary?: true;
  tertiary?: true;
  loading?: boolean;
  tiny?: true;
  large?: true;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;
const BaseButton = styled(RadixTheme.Button).withConfig({
  shouldForwardProp,
})<ButtonProps>`
  ${({ primary }) => primary && PrimaryButtonCss}
  ${({ secondary }) => secondary && SecondaryButtonCss}
  ${({ tertiary }) => tertiary && TertiaryButtonCss}
  ${({ large }) => large && LargeButtonCss}
  ${({ tiny }) => tiny && TinyButtonCss}
`;

function Button(props: ButtonProps) {
  if (props.primary && props.secondary) {
    throw new Error("Invalid state: Incompatible button states");
  }
  if (props.loading) {
    return (
      <BaseButton {...props}>
        <LoadingIcon /> {props.children}
      </BaseButton>
    );
  } else {
    return <BaseButton {...props} />;
  }
}

export { Button };
