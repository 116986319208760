type TextStyle =
  | "Display"
  | "Headline XL"
  | "Headline L"
  | "Headline M"
  | "Headline S"
  | "Body L"
  | "Body M"
  | "Body S"
  | "Label XS";

type ResponsiveTextStyle = {
  initial: TextStyle;
  sm: TextStyle;
  md: TextStyle;
  lg: TextStyle;
};

// Learn more about the underlying breakpoints here https://www.radix-ui.com/themes/docs/theme/breakpoints
// we can add more breakpoints if they are needed

type Sizes = {
  [K in TextStyle]: {
    initial: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";
    sm: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";
    md: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";
    lg: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";
  };
};

function getSize(
  style: TextStyle,
  breakpoint?: "initial" | "sm" | "md" | "lg",
): "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" {
  // Example implementation, adjust the size values as per your design system
  const sizes: Sizes = {
    Display: { initial: "8", sm: "9", md: "9", lg: "9" },
    "Headline XL": { initial: "6", sm: "8", md: "8", lg: "8" },
    "Headline L": { initial: "5", sm: "7", md: "7", lg: "7" },
    "Headline M": { initial: "4", sm: "6", md: "6", lg: "6" },
    "Headline S": { initial: "3", sm: "5", md: "5", lg: "5" },
    "Body L": { initial: "2", sm: "3", md: "3", lg: "3" },
    "Body M": { initial: "2", sm: "3", md: "3", lg: "3" },
    "Body S": { initial: "2", sm: "2", md: "2", lg: "2" },
    "Label XS": { initial: "1", sm: "1", md: "1", lg: "1" },
  };
  if (breakpoint && sizes[style][breakpoint]) {
    return sizes[style][breakpoint];
  }
  return sizes[style].lg;
}

export type { TextStyle, ResponsiveTextStyle };
export { getSize };
