import * as Radix from "@radix-ui/themes";
import React, { CSSProperties } from "react";
import {
  getSize,
  ResponsiveTextStyle,
  TextStyle,
} from "@/design-system/components/text/TextStyle";
import type { TextProps as RadixTextProps } from "@radix-ui/themes";

type TextElement = React.ElementRef<"span">;
type TextOwnProps = {
  textStyle: TextStyle | ResponsiveTextStyle;
  appearance?: TextAppearance;
};
type TextAppearance = "destructive" | "muted" | "normal";

interface TextProps
  extends Omit<RadixTextProps, "size" | "weight">,
    TextOwnProps {
  htmlFor?: string;
}

const TextV2 = React.forwardRef<TextElement, TextProps>(
  (
    {
      textStyle,
      style: incomingStyle,
      className,
      appearance = "normal",
      ...rest
    },
    ref,
  ) => {
    // Merge the incoming style with the styles from getStyledOverrides
    const { style: overridesStyle, ...overridesRest } = getStyledOverrides({
      textStyle,
      ...rest,
    });

    const combinedStyles: CSSProperties = {
      ...overridesStyle,
      ...incomingStyle,
    };

    let appearanceClasses = "";
    switch (appearance) {
      case "destructive":
        appearanceClasses = "text-destructive font-medium";
        break;
      case "muted":
        appearanceClasses = "text-muted-foreground";
        break;
      default:
        break;
    }

    const combinedClassNames = `${className || ""} ${appearanceClasses}`.trim();

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Radix.Text
        ref={ref}
        {...rest}
        {...overridesRest}
        style={combinedStyles}
        className={combinedClassNames}
      />
    );
  },
);

TextV2.displayName = "TextV2";

function getStyledOverrides({ textStyle }: TextOwnProps): {
  size: RadixTextProps["size"];
  weight: RadixTextProps["weight"];
  style: CSSProperties;
} {
  let size: RadixTextProps["size"];
  if (textStyle && typeof textStyle !== "object") {
    size = {
      initial: getSize(textStyle, "initial"),
      sm: getSize(textStyle, "sm"),
      md: getSize(textStyle, "md"),
      lg: getSize(textStyle, "lg"),
    };
  } else {
    // Apply responsive sizes
    size = {
      initial: getSize(textStyle.initial, "initial"),
      sm: getSize(textStyle.sm, "sm"),
      md: getSize(textStyle.md, "md"),
      lg: getSize(textStyle.lg, "lg"),
    };
  }
  const style: CSSProperties = {};
  if (textStyle === "Label XS") {
    style.fontFamily = "helvetica";
  }

  const weight = textStyle == "Body L" ? "medium" : "regular";

  return { size, weight, style };
}

export { TextV2 };
export type { TextProps };
export type { TextStyle as TextV2TextStyle };
